import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Header from '../../components/header'
import Container from '../../components/container'
import StoryIntro from '../../components/story-intro'
import Row from '../../components/row'
import Column from '../../components/column'

const PageHeader = <Header title="Der Prüffinger" />

const PrueffingerPage = ({ location }) => {
    const images = useStaticQuery(graphql`
      {
        allFile(filter: {relativeDirectory: {eq: "stories/prueffinger"}}, sort: {fields: name}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 1050
                height: 650
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
      }
    `)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/story/prueffinger/' }, { code: 'en', link: '/en/story/test-finger/' }]} pageLang="de" pageTitle="Der Prüffinger" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <StoryIntro>
                        <p>
                            Touch-Bedienungen haben die Welt erobert. Sie sind aus unserem Alltag nicht mehr wegzudenken.
                            Auch im automobilen Umfeld kommen sie zunehmend und in einigen Modellen sogar ausschließlich zum Einsatz.
                        </p>
                    </StoryIntro>
                    <p>
                        Um die kapazitiven Bedienoberflächen unter Laborbedingungen testen und Touch-Oberflächen harmonisieren zu können, hat GE-T einen Prüffinger entwickelt. 
                        Statt den vorher verwendeten Metallzylindern schuf GE-T ein neuartiges Werkzeug, den menschlichen Finger abzubilden. 
                        Gleichzeitig wurde damit ein neuer Standard geschaffen, der innerhalb des Volkswagen Konzerns und bei zahlreichen Zulieferern genutzt wird. 
                        Auch außerhalb der Automobilbranche setzen bereits mehrere namhafte Unternehmen den GE-T Prüffinger ein.
                    </p>
                    <Row>
                        <Column columnWidth={2}>
                            <GatsbyImage image={getImage(images.allFile.nodes[0])} alt="" />
                        </Column>
                        <Column columnWidth={2}>
                            <GatsbyImage image={getImage(images.allFile.nodes[2])} alt="" />
                        </Column>
                    </Row>
                    <p>
                        Der GE-T Prüffinger entspricht in sämtlichen haptischen und elektrischen Eigenschaften dem menschlichen Finger: 
                        Er ist selbst nach mehr als 1.000.000 Betätigungen höchst zuverlässig und funktioniert bei Temperaturen von minus 40 bis plus 90 Grad Celsius. 
                        Das breite Spektrum der Einsatzfeuchte liegt zwischen null und 98 Prozent relativer Luftfeuchtigkeit – sogar bei Salzwasser.
                    </p>
                    <p>
                        Dabei belassen wir es jedoch nicht. GE-T forscht weiter an innovativen Lösungen für die experimentelle Prüfung von Touch-Oberflächen.
                    </p>
                    <GatsbyImage image={getImage(images.allFile.nodes[1])} alt="" />
                </section>
            </Container>
        </Layout>
    )
}

export default PrueffingerPage