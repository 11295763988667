import * as React from 'react'
import InformationIcon from '../images/icons/information.svg'

const StoryIntro = ({ children }) => (
    <div className="story-intro">
        <div className="text" data-aos="fade-up" data-aos-duration="250">
            {children}
        </div>
        <div className="icon" data-aos="fade-up">
            <InformationIcon />
        </div>
    </div>
)

export default StoryIntro